import React, { useEffect, useRef, useState } from 'react';
import { MenuItem, MenuItemModal } from './comp/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { localizeString } from '../../helpers';
import { Button, Grid, Input, Label, Modal } from 'semantic-ui-react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { UpdateDirection } from '../../reducers/settings';
import CartItems from './comp/CartItems';

const getDimensions = ele => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
        height,
        offsetTop,
        offsetBottom,
    };
};

const MenuRes = () => {
    const orderList = useSelector(state => state.orderList);
    const { menu } = useSelector(state => state.userProp);
    const { sideImage, direction } = useSelector(state => state.settings);
    const [state, setState] = useState({})
    const [showCart, toggleCart] = useState(false)

    const [selectedItem, setSelected] = useState(false)
    const scrollContainerRef = useRef();
    const itemEls = useRef({});
    const dispatch = useDispatch();

    useEffect(() => {
        let element = document?.getElementById('leftSide')
        if (element) {
            element.style.width = '100%'
            element.style.marginLeft = '0px'
            element.style.marginRight = '0px'
        }
        document.addEventListener('scroll', trackScrolling);
        return () => {
            document.removeEventListener('scroll', trackScrolling);
            if (element) {
                element.style.width = '30%'
                element.style.marginLeft = '35%'
                element.style.marginRight = '35%'
            }
        }
    }, [])
    const trackScrolling = () => {
        const scrollY = window.scrollY;
        let offset = document.getElementById('banner_image');
        let controls_header = document.getElementById('controls_header');
        if (offset && controls_header) {
            const maxScroll = offset.offsetHeight; // Adjust this value for when you want the color transition to complete
            const opacity = Math.min(scrollY / maxScroll, 1);
            controls_header.style.backgroundColor = `rgba(255, 255, 255, ${opacity})`
        }

        (menu ? menu : []).map(item => {
            let category = item.category
            let element = document.getElementById(category.name + 'list')
            let appearedTag = document.getElementById(category.name + 'tag')
            if (element && appearedTag) {
                const { offsetBottom, offsetTop } = getDimensions(element);
                let appeared = (window.scrollY > offsetTop) && (window.scrollY < (offsetBottom + 70));
                if (appeared) {
                    let scrollContainer = scrollContainerRef.current.getElement()
                    if (scrollContainer) {
                        scrollContainer.scrollLeft = appearedTag.offsetLeft - 60;
                        appearedTag.classList.add('active');
                    }
                } else {
                    appearedTag.classList.remove('active');
                }
            }
            return false
        })
    };

    useEffect(() => {
        const { search } = state;
        let filteredMenu = JSON.parse(JSON.stringify(menu || []));
        if (search && search !== '') {
            var result = filteredMenu?.filter(item => {
                let { category, items } = item
                if (category.name.toLocaleLowerCase().includes(search) || category.name_ar.toLocaleLowerCase().includes(search)) {
                    return item
                } else if (items.filter(mennuItem => mennuItem.name.toLocaleLowerCase().includes(search) || (mennuItem.name_ar ? mennuItem.name_ar : '').includes(search)).length) {
                    item.items = items.filter(mennuItem => mennuItem.name.toLocaleLowerCase().includes(search) || (mennuItem.name_ar ? mennuItem.name_ar : '').includes(search))
                    return item
                } else {
                    return false
                }
            })
            setState({ ...state, menu: result })
        } else {
            setState({ ...state, menu: filteredMenu })
        }
    }, [state?.search, menu])

    const handleCategorySelection = (category) => {
        let element = itemEls.current[category];
        let offset = document.getElementById('banner_image');
        window.scrollTo({
            top: element.offsetTop + offset.offsetHeight - (60 + 52),
            left: 0,
            behavior: 'auto'
        });
    }
    if (showCart) {
        return <CartItems dismiss={() => toggleCart(false)} />
    }
    return (
        <div className='menuSection'>
            <div id='banner_image' style={{
                height: '30vw',
                minHeight: '200px',
                // padding: '200px 0',
                backgroundImage: `url("${sideImage}")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
            }}>
                <div id='controls_header' style={{ position: 'fixed', top: 0, right: 0, display: 'flex', justifyContent: 'flex-end', background: 'transparent', width: '100%', padding: '8px 16px', zIndex: 999 }}>
                    {
                        Object.values(orderList || {})?.length ?
                            <div style={{ position: 'relative' }}>
                                <Label color='red' size='small' floating style={{ top: '-6px', padding: '4px 6px',fontSize:'9px', borderRadius: '50%' }}>
                                    {Object.values(orderList)?.length}
                                </Label>
                                <Button circular icon='cart' style={{ background: '#fafafa' }} onClick={() => toggleCart(true)} />
                            </div>
                            :
                            ''
                    }
                    <Button circular icon='translate' style={{ background: '#fafafa' }} onClick={() => {
                        localStorage.removeItem('direction')
                        dispatch(UpdateDirection(direction === 'rtl' ? 'ltr' : 'rtl'))
                    }} />
                    <Button circular icon='info' style={{ background: '#fafafa' }} />

                </div>
            </div>
            <div className='listContainer' style={{ paddingBottom: '150px' }}>

                <div className='tagsContainer' style={{ direction: 'ltr' }}>
                    <Button className='searchIcon'
                        style={{ background: state?.searchActive ? 'var(--themeColor)' : 'white', color: state?.searchActive ? 'white' : 'var(--themeColor)', margin: '0 8px', fontSize: '14px' }}
                        icon={state?.searchActive ? 'cancel' : 'search'}
                        onClick={() => setState({ ...state, searchActive: !state?.searchActive, search: false })}></Button>
                    {
                        state?.searchActive ?
                            <Input
                                className='searchBar'
                                placeholder={'search'}
                                onChange={(e, { value }) => {
                                    document.body.scrollTop = 0;
                                    document.documentElement.scrollTop = 0;
                                    setState({ ...state, search: value.toLocaleLowerCase() })
                                }} />
                            :
                            <ScrollContainer id='scrollContainerid' hideScrollbars={true} className='tagsView' ref={scrollContainerRef}>
                                {
                                    menu?.map((item, index) => {
                                        let { category } = item

                                        return (
                                            <div key={index}
                                                id={category.name + 'tag'}
                                                className='tag'
                                                onClick={() => handleCategorySelection(index, 440)}
                                            >
                                                {
                                                    direction === 'rtl' ? category.name_ar : category.name
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </ScrollContainer>
                    }
                </div>

                {
                    state?.menu?.map((item, index) => <div key={item?.category?.id} ref={(element) => itemEls.current[index] = element}>
                        <div className='title' >
                            {
                                localizeString(item?.category, 'name')
                            }
                        </div>

                        <Grid doubling columns={4} className='list' id={item?.category?.name + 'list'} style={{ margin: '0 4px' }}>
                            <Grid.Row>
                                {

                                    item?.items?.map(rec => {
                                        return (
                                            <Grid.Column key={rec.id} style={{ padding: '10px' }} >
                                                <div key={rec.id} style={{ height: '100%' }} onClick={() => setSelected(rec)}>
                                                    <MenuItem data={rec} />
                                                </div>
                                            </Grid.Column>
                                        )
                                    })
                                }
                            </Grid.Row>
                        </Grid>
                    </div>
                    )
                }

            </div>
            {
                selectedItem ?

                    <Modal dimmer='blurring' size='small' className='menuSection' style={{ borderRadius: '8px' }} open={true} onClose={() => setSelected(false)} >
                        <div className='listContainer'>
                            <div className='list' style={{ margin: 0 }}>
                                <MenuItemModal data={selectedItem} dismiss={() => setSelected(false)} />

                            </div>
                        </div>

                    </Modal>
                    : ''
            }
            {/* {
                showCart ?
                    <Modal open onClose={() => toggleCart(false)} style={{ width: '100%', height: '100%' }}>
                        <Modal.Content style={{ width: '100%', height: '100%' }}>
                            <CartItems />
                        </Modal.Content>

                    </Modal> : ''

            } */}


        </div>


    );
}
export default MenuRes;