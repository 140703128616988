import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { localizeString } from '../../../helpers';
import { strings } from '../../../helpers/translation';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import { MenuItemModal } from './MenuItem';

const CartItems = ({ dismiss }) => {
    const orderList = useSelector(state => state.orderList)
    const settings = useSelector(state => state.settings);
    const [selectedItem, setSelected] = useState(false)
    return (
        <div className='menuSection'>
            <Header justifyContent='center'>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px 16px' }}>
                    <Button icon='arrow left' circular style={{ background: '#fafafa' }} onClick={() => {
                        dismiss()
                    }} />
                    <div>
                        Cart
                    </div>
                    <div />
                </div>
            </Header>
            <div className='listContainer'>
                <div className='list' style={{ paddingTop: '20px' }}>
                    {
                        Object.values(orderList).map(menuItem => {
                            return <div
                                key={menuItem?.id}
                                className='listItem'
                                style={{ boxShadow: '0 0 8px 4px #cacaca20', padding: '12px', marginBottom: '10px', borderRadius: '12px' }}
                                onClick={() => {
                                    setSelected(menuItem)
                                }}
                            >
                                <div className='itemInfo' style={{ padding: '0 8px 0 0', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: menuItem.pictures ? "65%" : "100%" }}>
                                    <div className='itemTitle' >
                                        <div style={{ display: 'flex', alignItems: 'center', color: '#4a4a4a' }}>
                                            {
                                                localizeString(menuItem, 'name')
                                            }
                                        </div>
                                        {
                                            menuItem.detail && <div className='itemDetails' style={{ WebkitBoxOrient: 'vertical' }}>{
                                                (localizeString(menuItem, 'detail') > 60) ? localizeString(menuItem, 'detail').substring(0, 60) + ' ...' : localizeString(menuItem, 'detail')
                                            }</div>
                                        }
                                        <div className='itemPrice'>
                                            {
                                                (menuItem.price === "0") ?
                                                    strings.priceOnSelection :
                                                    (menuItem.price + strings[settings?.currency])
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    menuItem.pictures &&
                                    <div style={{ display: 'flex', alignItems: 'center' }} >
                                        <div style={{ width: '110px', height: '115px' }}>
                                            {
                                                <img loading="lazy" alt='imageView' className='imageView' src={menuItem.pictures[0]} />
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        })
                    }
                </div>

            </div>
            {
                selectedItem ?

                    <Modal dimmer='blurring' size='small' className='menuSection' style={{ borderRadius: '8px' }} open={true} onClose={() => setSelected(false)} >
                        <div className='listContainer'>
                            <div className='list' style={{ margin: 0 }}>
                                <MenuItemModal data={selectedItem} dismiss={() => setSelected(false)} />

                            </div>
                        </div>

                    </Modal>
                    : ''
            }
        </div>
    );
};

export default CartItems;